/* purgecss start ignore */
@tailwind components;
/* purgecss end ignore */

@layer components {
  .separator {
    height: 1px;
    background: #d9d9d9;
  }

  .parcel-details-address-url:hover > h3,
  .parcel-details-address-url:hover > p {
    color: #cd2f23 !important;
  }

  .parcel-details-overline {
    @apply mb-2 font-roboto text-sm font-normal uppercase text-secondary-300 tracking-0.089rem;
  }

  .parcel-details-text {
    @apply font-roboto text-1.1em text-secondary-400 tracking-0.04rem;
  }

  .footer-text {
    @apply font-roboto text-0.82rem text-secondary-300 tracking-0.02rem font-normal;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
    @apply flex flex-wrap;
  }

  .btn {
    @apply inline-flex items-center px-5 py-3 mx-auto text-base font-bold text-white uppercase rounded-full font-NunitoSans border-1 focus:outline-none border-color-primary-brighter focus:ring-4 ring-color-primary-lightest disabled:opacity-50 disabled:pointer-events-none tracking-15rem;
  }

  .btn [data-button-slot="label"] {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
  }

  .btn [data-button-slot="icon"] {
    display: inherit;
  }

  .btn [data-button-slot="icon"] > * {
    @apply inline-block w-6 h-6 select-none fill-current;
  }

  .btn-size-large {
    @apply px-6 py-4 leading-6;
  }

  .btn-size-medium [data-button-slot="icon"] {
    @apply mr-2;
  }

  .btn-size-medium [data-button-slot="icon"] > * {
  }

  .btn-size-large [data-button-slot="icon"] {
    @apply mr-4;
  }

  .md\:col-1,
  .md\:col-2,
  .md\:col-4,
  .md\:col-5,
  .md\:col-6 {
    padding-right: 15px;
    padding-left: 15px;
    min-height: 1px;
    @apply relative w-full;
  }

  @variants responsive {
    .col,
    .col-1,
    .col-2,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-12 {
      padding-right: 15px;
      padding-left: 15px;
      min-height: 1px;
      @apply relative w-full;
    }
    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col {
      flex-basis: 0;
      @apply flex-grow max-w-full;
    }
  }
}
