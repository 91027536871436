:root {
  --color-primary-main: #de483c;
  --color-primary-brighter: #ca3227;
  --color-primary-lighter: #f06a5f;
  --color-primary-lightest: #ffb2ac;
  --color-primary-darker: #b13930;
  --color-secondary-300: #7b7b7b;
  --color-secondary-200: #d9d9d9;
  --color-secondary-100: #ececec;
  /* ... */
}
/* purgecss start ignore */
@tailwind base;
/* purgecss end ignore */

body > #__next {
  @apply h-full;
}

main {
  padding-top: 60px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  @apply box-border w-full mx-auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold font-NunitoSans text-secondary-400 leading-header;
}

h2 {
  @apply font-bold text-h2 tracking-h2;
}

h4 {
  @apply text-h4 tracking-h4;
}

p {
  margin-top: 0;
  @apply mb-4;
}

.navbar-menu {
  @apply flex flex-col p-0 ml-auto list-none;
}

.navbar-link {
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.06rem;
  line-height: 1.5;
  @apply font-NunitoSans font-semibold text-1.1em md:px-2 px-0 py-2 block;
}

.navbar-link:hover,
.navbar-link:focus,
.navbar-link:active {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-link.dropdown-toggle {
  @apply focus:outline-none;
}

.navbar-link.dropdown-toggle::after {
  vertical-align: 0.12em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  @apply inline-block w-0 h-0 ml-2;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.text-label-input {
  @apply font-roboto tracking-0.02rem text-sm font-normal inline-block mb-2 text-secondary-300;
}

.input {
  @apply px-3 py-3 leading-relaxed border rounded border-secondary-300 text-secondary-300 focus:outline-none focus:border-color-primary-main;
}

.input:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 72, 60, 0.2);
}

.locale-dropdown-menu {
  position: absolute;
  top: 100%;
  left: -30px;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.hideOverflow {
  overflow-y: hidden;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(87, 86, 88);
  opacity: 0.9;
}

.line {
  height: 0.8px;
}

.modal {
  transition: opacity 0.25s ease;
  z-index: 999;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

/* HAMBURGER MENU */

.hamburger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #333;
}

.hamburger__top-bun {
  transform: translateY(-5px);
}

.hamburger__bottom-bun {
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(-1px);
}

.open .hamburger__top-bun {
  transform: rotate(45deg) translateY(0px);
}

.open .hamburger__bottom-bun {
  transform: rotate(-45deg) translateY(0px);
}

.parent {
  position: relative;
}

.child {
  transform: translate(-50%, -50%);
  @apply absolute z-10 w-6 top-1/2 left-1/2;
}

.spinner {
  position: relative;
  color: transparent !important;
  pointer-events: none;
}

.spinner::after {
  content: "";
  position: absolute !important;
  display: block;
  width: 100%;
  height: 100%;
  border: 2.2px solid white;
  border-radius: 2rem;
  border-right-color: transparent;
  border-top-color: transparent;
  animation: spinAround 500ms infinite linear;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-reach-dialog-overlay] {
  z-index: 20 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

[data-reach-dialog-content] {
  width: auto !important;
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  [data-reach-dialog-content] {
    max-width: 500px !important;
    margin: 30px auto !important;
  }
}

.dialog-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-clip: padding-box;
  outline: 0;
}
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.dialog-header-title {
  font-size: 1.4rem;
  line-height: 1.5;
  @apply mb-0 font-bold font-NunitoSans tracking-07rem;
}

.dialog-close-btn {
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  @apply p-0 ml-auto font-bold bg-transparent border-0 appearance-none;
}

.dialog-close-btn:focus {
  outline: none;
}

.dialog-body {
  flex: 1 1 auto;
  @apply relative p-4;
}

.dialog-body h5 {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #7b7b7b;
  letter-spacing: 0.089rem;
  @apply mb-4 font-normal font-roboto;
}

.dialog-body p {
  font-size: 1.1em;
  color: #424242;
  letter-spacing: 0.04rem;
  @apply mb-4 font-normal font-roboto;
}

.dialog-body img {
  max-width: initial;
}

.dialog-body section {
  margin-bottom: 16px;
}
